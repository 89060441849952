import { LOGOUT_BEGIN } from '../actions/actionType'

const initialState = {
  sidebarShow: true,
  theme: 'default',
}
export default function SidebarReducer(state = initialState, { type, ...rest }) {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case LOGOUT_BEGIN:
      return { ...state, ...initialState }
    default:
      return state
  }
}
