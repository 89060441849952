const initialState = {
  theme: 'default',
}
export default function ThemeReducer(state = initialState, { type, ...rest }) {
  switch (type) {
    case 'set':
      return { ...state, ...initialState }
    default:
      return state
  }
}
