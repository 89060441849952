import axios from 'axios'
import { ACCESS_TOKEN_NAME } from '../constants/Constants'

const headers = {
  'Content-Type': 'application/json',
  accept: 'application/json',
}

const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME)
    const auth = token ? `Bearer ${token}` : ''
    config.headers = {
      ...headers,
      Authorization: `${auth}`,
    }
    return config
  },
  (error) => Promise.reject(error),
)

const token = localStorage.getItem(ACCESS_TOKEN_NAME) || ''
const exportHttp = axios.create({
  baseURL: process.env.REACT_APP_EXPORT_URL,
  headers: { ...headers, Authorization: `Bearer ${token}` },
})
export const exportData = exportHttp

export default http
